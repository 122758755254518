<template>
  <div class="usecases">
    <UsecasesDashboard id="use-cases-pg" />
    <UsescasesIdentityVerification />
    <UsecasesIdentityFraudDetection />
    <UsecasesUserOnboarding />
    <UsecasesAgeVerification />
    <!-- <UsecasesGoPasswordless /> -->
    <GetStarted />
  </div>
</template>

<script>
import UsecasesDashboard from "../components/UsecasesTverifyComponents/UsecasesDashboard.vue";
import UsescasesIdentityVerification from "../components/UsecasesTverifyComponents/UsecasesIdentityVerification.vue";
import UsecasesIdentityFraudDetection from "../components/UsecasesTverifyComponents/UsecasesIdentityFraudDetection.vue";
import UsecasesUserOnboarding from "../components/UsecasesTverifyComponents/UsecasesUserOnboarding.vue";
import UsecasesAgeVerification from "../components/UsecasesTverifyComponents/UsecasesAgeVerification.vue";
//import UsecasesGoPasswordless from "../components/UsecasesTverifyComponents/UsecasesGoPasswordless.vue";
import GetStarted from "../components/GetStarted";

export default {
  name: "Usecases",
  components: {
    UsecasesDashboard,
    UsescasesIdentityVerification,
    UsecasesIdentityFraudDetection,
    UsecasesUserOnboarding,
    UsecasesAgeVerification,
    // UsecasesGoPasswordless,
    GetStarted,
  },
  data() {
    return {
      title:
        "Tekkis Digital Identity Verification Solutions - eKYC with Face Biometrics authentication",
      description:
        "Know Your Customer. Identify, verify, and onboard with tekkis. Keep your business and customers secure with Tekkis t-Verify. Custom solutions available.",
    };
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          name: "description",
          content: this.description,
        },
        {
          property: "og:title",
          content: this.title,
        },
        { property: "og:site_name", content: "Tekkis" },
        { property: "og:type", content: "website" },
      ],
    };
  },

  methods: {
    updateMetadata() {
      // Update the data properties to change the metadata
      this.title = "description";
      this.description =
        "Know Your Customer. Identify, verify, and onboard with tekkis. Keep your business and customers secure with Tekkis t-Verify. Custom solutions available.";
    },
  },
};
</script>
