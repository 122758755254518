import { render, staticRenderFns } from "./UsecasesDashboard.vue?vue&type=template&id=818a89c2&scoped=true&"
var script = {}
import style0 from "./UsecasesDashboard.vue?vue&type=style&index=0&id=818a89c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "818a89c2",
  null
  
)

export default component.exports